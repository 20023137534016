function getCurrentRootFavicon(): string {
  if (isShift()) {
    return `${useRuntimeConfig().app.cdnURL}/shift-icon-32.png`
  }

  return `${useRuntimeConfig().app.cdnURL}/ON_icon.png`
}

export default function useRootFavicon(): Ref<string | undefined> {
  return useState<string | undefined>('favicon', getCurrentRootFavicon)
}
